import { useEffect, useState } from 'react';
import { fetchNotificationCount } from './utils/notifications';
import { getTenant } from './helpers';

function usePollNotificationCount() {
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    if (
      process.env.REACT_APP_IBL_ENABLE_NOTIFICATION === 'true' &&
      getTenant()
    ) {
      const pollInterval =
        process.env.REACT_APP_IBL_NOTIFICATION_POLL_INTERVAL || 300000;

      // Initial poll
      fetchNotificationCount(notificationCountCallBack);

      const interval = setInterval(() => {
        // Fetch notification count from backend
        fetchNotificationCount(notificationCountCallBack);
      }, pollInterval);

      return () => clearInterval(interval);
    }
  }, []);

  const notificationCountCallBack = (data) => {
    setNotificationCount(data?.body?.count || 0);
  };

  return { notificationCount, setNotificationCount };
}

export default usePollNotificationCount;
