import React, {useEffect, useState} from 'react';

const UseMobile = ({maxViewPoint=480}) => {
    const [isMobile, setIsMobile] = useState(false)

    const handleDetectMobileDisplay = () => {
        console.log('mobile display : ', window.innerWidth <= maxViewPoint)
        setIsMobile(window.innerWidth <= maxViewPoint)
    }

    useEffect(() => {
        handleDetectMobileDisplay()
        window.addEventListener('resize', handleDetectMobileDisplay)
        return ()=>{
            window.removeEventListener("resize", handleDetectMobileDisplay)
        }
    }, []);
    return {
        isMobile
    }
};

export default UseMobile;